import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import CouponBox from "./CouponBox";

function AlertBox({
  userSubscription,
  isUpgradingPlan,
  alertModal,
  setAlertModal,
  isLoading,
  setIsLoading,
  handleUpdatePlan,
  couponError,
  setCouponError,
}) {
  const [couponModal, setCouponModal] = React.useState(false);

  const handleClose = () => {
    setCouponModal(false);
    setAlertModal(false);
  };

  const handleAgree = () => {
    setCouponModal(true);
  };

  function getMonthlyResetDate(startDate, endDate, interval) {
    const originalDate = new Date(startDate);
    const endDateObj = new Date(endDate);
    const currentDate = new Date();

    if (interval === 1) {
      return endDate;
    } else {
      const yearDiff = currentDate.getFullYear() - originalDate.getFullYear();
      const monthDiff = currentDate.getMonth() - originalDate.getMonth();
      let totalMonthDiff = yearDiff * 12 + monthDiff;

      const newDate = new Date(originalDate);
      newDate.setMonth(newDate.getMonth() + totalMonthDiff);

      if (newDate <= currentDate) {
        newDate.setMonth(newDate.getMonth() + 1);
      }

      const isoDate = newDate.toISOString();

      if (newDate > endDateObj) {
        return endDate;
      }

      return isoDate;
    }
  }

  const monthlyResetDate = getMonthlyResetDate(
    userSubscription?.subscriptions?.base?.startDate,
    userSubscription?.subscriptions?.base?.endDate,
    userSubscription?.subscriptions?.base?.durationInMonths
  );

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  return (
    <React.Fragment>
      {couponModal && (
        <CouponBox
          couponModal={couponModal}
          setCouponModal={setCouponModal}
          alertModal={alertModal}
          setAlertModal={setAlertModal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleUpdatePlan={handleUpdatePlan}
          couponError={couponError}
          setCouponError={setCouponError}
        />
      )}
      <Dialog
        open={alertModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontSize: "16px" }}
          >
            Your subscription will be updated on{" "}
            {isUpgradingPlan()
              ? formatTimestamp(monthlyResetDate)
              : formatTimestamp(userSubscription?.subscriptions?.base?.endDate)}
            . Changes will take effect from this date. Are you sure you want to
            proceed with changing your plan?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ fontSize: "14px" }}>
            Disagree
          </Button>
          <Button onClick={handleAgree} autoFocus sx={{ fontSize: "14px" }}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default AlertBox;
