import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Button
} from "@mui/material";
import { INTEGRATIONTYPE } from "../integrations"
import { API_SERVICE_BACKEND_2, salesforceOauthConfig } from "src/config"
import { useAuth } from 'src/contexts/auth/firebase-context';
import SalesforceLogo from './salesforce-logo';
import LinkIcon from "src/components/logos/linkIcon.png";
import axios from 'axios';
import { INTEGRATION_TYPE } from 'src/helpers/constants';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router';

const apiEndpoints = Object.freeze({
    ADD_SALESFORCE_ACCOUNT: `${API_SERVICE_BACKEND_2}/salesforce-integrations/add`,
    REMOVE_SALESFORCE_ACCOUNT: `${API_SERVICE_BACKEND_2}/salesforce-integrations/remove`,
});

export const getSalesforceAuthURL = () => {
    const { oauthUrl, redirectUrl, clientId, scopes } = salesforceOauthConfig
    const authURL = `${oauthUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scopes}`;
    return authURL;
};

const SalesforceIntegrationSettings = () => {
    const { user, setUserInfo } = useAuth();
    const navigate = useNavigate();
    const [hasSalesforceIntegrated, setHasSalesforceIntegrated] = useState(false);

    useEffect(() => {
        setHasSalesforceIntegrated(
            Boolean(user.integrations.salesforce?.integrations?.hasSalesforceIntegrated)
        );
    }, [user]);

    useEffect(() => {
        const url = window?.location?.href ?? '';
        if(!!url) {
            const code = new URLSearchParams(url).get('code');
            navigate('/dashboard/account?tab=integrations')
            addSalesforceIntegration(code);
        }
    }, []);

    const initiateSalesforceOauthFlow = () => {
        window.location.href = getSalesforceAuthURL();
    };

    const addSalesforceIntegration = async (code) => {

        if(!code) {
            return;
        }

        try {
            await axios.post(apiEndpoints.ADD_SALESFORCE_ACCOUNT, {
                code,
                type: INTEGRATION_TYPE.SALESFORCE,
                userEmail: user.email,
            });

            toast.success('Salesforce account connected!');
            setHasSalesforceIntegrated(true);
            setUserInfo(user.email);

        } catch (error) {
            console.error("Error updating isAuto:", error);
            toast.error('Error occured while connecting Salesforce account!');
        }
    };

    const removeSalesforceIntegration = async () => {
        try {
            await axios.post(apiEndpoints.REMOVE_SALESFORCE_ACCOUNT, {
                type: INTEGRATION_TYPE.SALESFORCE,
                userEmail: user.email,
            });

            toast.success('Salesforce account removed!');
            setHasSalesforceIntegrated(false);
    
        } catch (error) {
            console.error("Error updating isAuto:", error);
            toast.error('Error occured while removing Salesforce account!');
        }
    };

    return (
        <Card style={{ marginTop: '1rem' }}>
            <CardContent style={{ padding: "24px" }}>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "8px",
                    }}
                >
                    <SalesforceLogo />

                    <Button
                        variant="text"
                        onClick={hasSalesforceIntegrated ? removeSalesforceIntegration : initiateSalesforceOauthFlow}
                        size="small"
                        sx={{
                            color: "#050C46",
                            border: "1px solid #ECE9F5",
                            marginLeft: "5px",
                            width: "max-content",
                            padding: "5px 10px",
                            fontSize: "0.8em"
                        }}
                    >
                        <img
                            src={LinkIcon}
                            style={{ marginRight: "8px" }}
                        />
                        {hasSalesforceIntegrated ? 'Disconnect' : 'Connect'}

                    </Button>
                </div>
            </CardContent>
        </Card>
    )
}

export default SalesforceIntegrationSettings
