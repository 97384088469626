import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router";
import { paths } from "src/paths";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { firebaseApp } from "src/libs/firebase";
import { Issuer } from "src/utils/auth";
import { getUserInfo } from "src/pages/dashboard/account";
import {
  API_ADMIN_SERVICE_BACKEND,
  API_SERVICE_BACKEND,
  API_SERVICE_BACKEND_2,
} from "src/config";
import axios from "axios";
import {
  clearLocalStorage,
  clearSessionStorage,
  getSessionStorage,
  setSessionStorage,
} from "src/utils/storage";
import { generateHMAC } from "src/pages/auth-demo/login/modern";
import { useSearchParams } from "src/hooks/use-search-params";
import { toast } from "react-hot-toast";
import { INTEGRATION_TYPE } from "src/helpers/constants";

const auth = getAuth(firebaseApp);

var ActionType;
(function (ActionType) {
  ActionType["AUTH_STATE_CHANGED"] = "AUTH_STATE_CHANGED";
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === "AUTH_STATE_CHANGED") {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

export const AuthContext = createContext({
  ...initialState,
  issuer: Issuer.Firebase,
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  sendPasswordResetEmail: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { pathname, state: locState } = useLocation();

  setSessionStorage("currentUrl", pathname);

  console.log("locState", locState);

  const searchParams = useSearchParams();
  const returnTo = searchParams.get("returnTo");
  const authToken =
    searchParams.get("token") || getSessionStorage("jwtLoginToken");
  console.log("authToken====>", authToken);

  const _signOut = useCallback(async () => {
    await signOut(auth);
    clearLocalStorage();
    clearSessionStorage();
    dispatch({
      type: ActionType.AUTH_STATE_CHANGED,
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });
  }, []);

  const _forgotPassword = useCallback(async (email) => {
    await sendPasswordResetEmail(auth, email);
  }, []);

  const magicLinkLogin = async (authToken) => {
    clearSessionStorage();

    try {
      console.log("auth: signed out!");

      let authData = await axios
        .get(
          `${API_ADMIN_SERVICE_BACKEND}/verifyJWTAuthToken?token=${authToken}`
        )
        .then((res) => {
          return res.data.data;
        })
        .catch((err) => {
          toast.error(err.response.data.error);
          setTimeout(() => {
            window.location.href = `${pathname}`;
          }, 2000);
        });
      let requestStatus = searchParams?.get("status") || null;
      if (authData.userEmail && requestStatus == "accept") {
        await axios
          .put(`${API_SERVICE_BACKEND}/invitation/update`, {
            receiver_mail: authData.userEmail,
            sender_mail: authData.email,
            status: requestStatus,
          })
          .then((res) => res.data)
          .catch((err) => {
            console.log(err);
          });
      }
      const userInfo = await getUserInfo(authData.email);

      if (!userInfo) {
        clearSessionStorage();
        setSessionStorage("magic_link_status", "in-active");
        window.location.href = `${pathname}`;
      } else {
        setSessionStorage("jwtLoginToken", authToken);
        // console.log({ authData, userInfo });
        const user = {
          email: userInfo.email,
          displayName: userInfo.name,
          photoURL: userInfo.profile_image,
          firstName: userInfo.name.split(" ")[0],
          lastName: userInfo.name.split(" ")[1],
        };
        setSessionStorage("fullName", userInfo.name);
        setSessionStorage("userEmail", userInfo.email);
        setSessionStorage("ownerEmail", authData.userEmail || "");
        setSessionStorage("companyName", userInfo.companyName);
        setSessionStorage("organization_id", userInfo.organization_id);
        setSessionStorage("photoUrl", userInfo.profile_image);
        setSessionStorage("firstName", userInfo.name.split(" ")[0]);
        setSessionStorage("lastName", userInfo.name.split(" ")[1]);
        setSessionStorage(
          "SubscriptionStartDate",
          userInfo?.userSubscription?.subscriptions?.base?.startDate
        );
        setSessionStorage(
          "SubscriptionEndDate",
          userInfo?.userSubscription?.subscriptions?.base?.endDate
        );
        setSessionStorage(
          "SubscriptionType",
          userInfo?.userSubscription?.subscriptions?.base?.planId
        );
        if (userInfo?.userSubscription) {
          setSessionStorage("Paid", true);
        } else {
          setSessionStorage("Paid", false);
        }

        let integrationsData = await axios
          .get(
            `${API_SERVICE_BACKEND}/getUserIntegrations/${encodeURIComponent(
              authData.email
            )}`
          )
          .then((res) => res.data)
          .catch((err) => {
            console.log(err);
          });

        updateAuthState({
          isAuthenticated: true,
          user: {
            integrations: {
              hubspot: integrationsData.find(
                (integration) => integration.type === "hubspot"
              ),
              cronofy: userInfo.extra?.accessToken,
              zoho: integrationsData.find(
                (integration) => integration.type === "zoho"
              ),
              zapier: integrationsData.find(
                (integration) => integration.type === "zapier"
              ),
              pipedrive: integrationsData.find(
                (integration) => integration.type === "pipedrive"
              ),
              salesforce: integrationsData.find(
                (integration) => integration.type === INTEGRATION_TYPE.SALESFORCE
              ),
            },
            dbRef: userInfo,
            ref: user,
            id: user.uid,
            avatar: user.photoURL || undefined,
            email: authData.email || "UNKNOWN",
            name: user.displayName || "UNKNOWN",
            plan: user?.data?.plan || "GROW", // SCALE | GROW
            organisation: user.data?.organisation || "UNKNOWN",
          },
        });
        const hash = await generateHMAC(
          getSessionStorage("userEmail"),
          process.env.REACT_APP_INTERCOM_HASH_KEY
        );
        window.Intercom("boot", {
          app_id: "qaecvjwi",
          email: getSessionStorage("userEmail"),
          name: getSessionStorage("fullName"),
          user_hash: hash,
        });

        const currentUrl = getSessionStorage("currentUrl");
        console.log({ currentUrl, ref: document.referrer });

        if (currentUrl === "/auth-main/login/v2") {
          console.log("locState redirecting to", paths.dashboard.campaigns);
          if (returnTo) {
            console.log("locState redirecting to", returnTo);
            navigate(returnTo, { state: { prev: currentUrl } });
          } else {
            navigate(paths.dashboard.campaigns, {
              state: { prev: currentUrl },
            });
          }
        }
      }
    } catch (ex) {
      console.log("error", ex);
    }
  };

  const setUserInfo = async (email) => {
    const userInfo = await getUserInfo(email);

    const user = {
      email: userInfo.email,
      displayName: userInfo.name,
      photoURL: userInfo.profile_image,
      firstName: userInfo.name.split(" ")[0],
      lastName: userInfo.name.split(" ")[1],
    };

    let integrationsData = await axios
    .get(
      `${API_SERVICE_BACKEND}/getUserIntegrations/${encodeURIComponent(
        email
      )}`
    )
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });

    dispatch({
      type: ActionType.AUTH_STATE_CHANGED,
      payload: {
        isAuthenticated: true,
        user: {
          integrations: {
            hubspot: integrationsData.find(
              (integration) => integration.type === "hubspot"
            ),
            cronofy: userInfo.extra?.accessToken,
            zoho: integrationsData.find(
              (integration) => integration.type === "zoho"
            ),
            zapier: integrationsData.find(
              (integration) => integration.type === "zapier"
            ),
            pipedrive: integrationsData.find(
              (integration) => integration.type === "pipedrive"
            ),
            salesforce: integrationsData.find(
              (integration) => integration.type === INTEGRATION_TYPE.SALESFORCE
            ),
          },
          dbRef: userInfo,
          ref: user,
          id: user.uid,
          avatar: user.photoURL || undefined,
          email: user.email || "UNKNOWN",
          name: user.displayName || "UNKNOWN",
          plan: user?.data?.plan || "GROW", // SCALE | GROW
          organisation: user.data?.organisation || "UNKNOWN",
        },
      },
    });
  }

  const handleAuthStateChanged = useCallback(
    async (user) => {
      console.log("user: :: ::: ", user);
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.
        const userInfo = await getUserInfo(user.email);

        if (userInfo) {
          setSessionStorage("photoUrl", userInfo.profile_image);
          setSessionStorage("organization_id", userInfo.organization_id);
          setSessionStorage("userEmail", userInfo.email);
          setSessionStorage("companyName", userInfo.companyName);
          setSessionStorage("fullName", userInfo.name);
          setSessionStorage("firstName", userInfo.name.split(" ")[0]);
          setSessionStorage("lastName", userInfo.name.split(" ")[1]);
          setSessionStorage(
            "SubscriptionStartDate",
            userInfo.userSubscription?.subscriptions?.base?.startDate
          );
          setSessionStorage(
            "SubscriptionEndDate",
            userInfo.userSubscription?.subscriptions?.base?.endDate
          );
          setSessionStorage(
            "SubscriptionType",
            userInfo?.userSubscription?.subscriptions?.base?.planId
          );
          if (userInfo?.userSubscription) {
            setSessionStorage("Paid", true);
          } else {
            setSessionStorage("Paid", false);
          }

          let integrationsData = await axios
            .get(
              `${API_SERVICE_BACKEND}/getUserIntegrations/${encodeURIComponent(
                user.email
              )}`
            )
            .then((res) => res.data)
            .catch((err) => {
              console.log(err);
            });

          // console.log(userInfo);
          dispatch({
            type: ActionType.AUTH_STATE_CHANGED,
            payload: {
              isAuthenticated: true,
              user: {
                integrations: {
                  hubspot: integrationsData.find(
                    (integration) => integration.type === "hubspot"
                  ),
                  cronofy: userInfo.extra?.accessToken,
                  zoho: integrationsData.find(
                    (integration) => integration.type === "zoho"
                  ),
                  zapier: integrationsData.find(
                    (integration) => integration.type === "zapier"
                  ),
                  pipedrive: integrationsData.find(
                    (integration) => integration.type === "pipedrive"
                  ),
                  salesforce: integrationsData.find(
                    (integration) => integration.type === INTEGRATION_TYPE.SALESFORCE
                  ),
                },
                dbRef: userInfo,
                ref: user,
                id: user.uid,
                avatar: user.photoURL || undefined,
                email: user.email || "UNKNOWN",
                name: user.displayName || "UNKNOWN",
                plan: user?.data?.plan || "GROW", // SCALE | GROW
                organisation: user.data?.organisation || "UNKNOWN",
              },
            },
          });
        } else {
          console.log("auth: user not found in db");
          dispatch({
            type: ActionType.AUTH_STATE_CHANGED,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } else {
        if (!authToken) {
          console.log("auth: user not found in db");
          dispatch({
            type: ActionType.AUTH_STATE_CHANGED,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        } else {
          magicLinkLogin(authToken);
        }
      }
    },
    [dispatch]
  );
  // useEffect(() => {
  //   // console.log("check auth====>", auth);
  //   let ownerEma = getSessionStorage("ownerEmail") || null;
  //   if (authToken && ownerEma) {
  //     handleAuthStateChanged();
  //   }
  // }, [])

  useEffect(
    () => {
      // let ownerEma = getSessionStorage("ownerEmail") || null;
      if (authToken) {
        handleAuthStateChanged();
      } else {
        onAuthStateChanged(auth, handleAuthStateChanged);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const _signInWithEmailAndPassword = useCallback(async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password);
  }, []);

  // const signInWithGoogle = useCallback(async () => {
  //   const provider = new GoogleAuthProvider();

  //   await signInWithPopup(auth, provider);
  // }, []);
  const signInWithGoogle = useCallback(async () => {
    try {
      const provider = new GoogleAuthProvider();
      const finalResult = await signInWithPopup(auth, provider);
      const { user } = finalResult;
      return user;
    } catch (ex) {
      console.log({ ex });
      return null;
    }
  }, []);

  const _createUserWithEmailAndPassword = useCallback(
    async (email, password) => {
      await createUserWithEmailAndPassword(auth, email, password);
    },
    []
  );

  const updateAuthState = (payload) =>
    dispatch({
      type: "AUTH_STATE_CHANGED",
      payload,
    });

  return (
    <AuthContext.Provider
      value={{
        ...state,
        updateAuthState,
        issuer: Issuer.Firebase,
        createUserWithEmailAndPassword: _createUserWithEmailAndPassword,
        signInWithEmailAndPassword: _signInWithEmailAndPassword,
        signInWithGoogle,
        signOut: _signOut,
        forgotPassword: _forgotPassword,
        setUserInfo: setUserInfo
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
