import { Toaster as HotToaster } from "react-hot-toast";
import { alpha, useTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";

export const Toaster = () => {
  const theme = useTheme();

  return (
    <>
      <HotToaster
        position="top-right"
        toastOptions={{
          style: {
            backdropFilter: "blur(6px)",
            background: alpha(theme.palette.neutral[900], 0.8),
            color: theme.palette.common.white,
            boxShadow: theme.shadows[16],
          },
        }}
      />
      <ToastContainer />
    </>
  );
};
