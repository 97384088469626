import { paths } from "src/paths";

export const STATS_MAP = {
  leadsGen: {
    title: "Leads Generated",
    iconURL: "https://img.icons8.com/fluency/64/omnichannel.png",
    redirect: paths.dashboard.allContacts + "?tab=all",
  },
  totalEmailed: {
    title: "Sent",
    iconURL: "https://img.icons8.com/fluency/64/filled-sent.png",
    redirect: paths.dashboard.allContacts + "?tab=emailsSent",
  },
  delivered: {
    title: "Delivered",
    iconURL: "https://img.icons8.com/fluency/64/reading-confirmation.png",
    // redirect: paths.dashboard.campaigns,
    redirect: paths.dashboard.allContacts + "?tab=all",
  },
  "rates.delivered": {
    title: "Delivery Rate",
    iconURL: "https://img.icons8.com/arcade/64/percentage.png",
    // redirect: paths.dashboard.campaigns,
    redirect: paths.dashboard.allContacts + "?tab=all",
  },
  emailsOpened: {
    title: "Opened",
    iconURL: "https://img.icons8.com/color/64/vision.png",
    redirect: paths.dashboard.allContacts + "?tab=views",
  },
  "rates.opened": {
    title: "Open Rate",
    iconURL: "https://img.icons8.com/arcade/64/percentage.png",
    // redirect: paths.dashboard.campaigns,
    redirect: paths.dashboard.allContacts + "?tab=views",
  },
  clicks: {
    title: "Clicked",
    iconURL:
      "https://img.icons8.com/external-soft-fill-juicy-fish/40/external-arrow-cursors-soft-fill-soft-fill-juicy-fish-5.png",
    redirect: paths.dashboard.allContacts + "?tab=clicks",
  },
  "rates.clicked": {
    title: "Click Rate",
    iconURL: "https://img.icons8.com/arcade/64/percentage.png",
    // redirect: paths.dashboard.campaigns,
    redirect: paths.dashboard.allContacts + "?tab=clicks",
  },
  conversations: {
    title: "Conversations",
    iconURL: "https://img.icons8.com/arcade/64/speech-bubble.png",
    redirect: paths.dashboard.allContacts + "?tab=conversations",
  },
  "rates.conversations": {
    title: "Conversation Rate",
    iconURL: "https://img.icons8.com/arcade/64/percentage.png",
    // redirect: paths.dashboard.campaigns,
    redirect: paths.dashboard.allContacts + "?tab=conversations",
  },
  meetings: {
    title: "Meetings Booked",
    iconURL: "https://img.icons8.com/color/48/groups.png",
    redirect: paths.dashboard.allContacts + "?tab=meetings",
  },
  "rates.meetings": {
    title: "Meeting Rate",
    iconURL: "https://img.icons8.com/arcade/64/percentage.png",
    // redirect: paths.dashboard.campaigns,
    redirect: paths.dashboard.allContacts + "?tab=meetings",
  },
};

export const INTEGRATION_TYPE = Object.freeze({
  HUBSPOT: "hubspot",
  ZOHO: "zoho",
  ZAPIER: "zapier",
  PIPEDRIVE: "pipedrive",
  SALESFORCE: 'salesforce',
})